<template>
  <div style="margin-top: 20px">
    <div style="margin-top: 20px">
      <p style="text-align: right">
        <el-button size="small" icon="el-icon-document-add" @click="dialogVisible = true">添加
        </el-button>
      </p>
      <el-timeline id="listUl" style="overflow-x: hidden; overflow-y: auto;" :style="'height:' + ULHeight">
        <el-timeline-item :timestamp="item.tpCreationTime" placement="top" v-for="(item, index) in postmanList"
                          :key="index">
          <el-card>
            <el-row type="flex" justify="end">
              <el-col :span="1"><i class="el-icon-delete" style="cursor:pointer;font-size: 16px"
                                   @click="del(item)"></i>
              </el-col>
            </el-row>
            <div @click="showDataFun(item)">
              <h4 style="margin-top: 0">第 {{ postmanList.length - index }} 封👨‍❤️‍💋‍👨{{ item.tpTitle }}</h4>
              <div style="height: 50px" class="ql-editor" v-html="item.tpContent"/>
            </div>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
    <el-dialog
        top="5vh"
        :visible.sync="dialogShowVisible"
        width="95%">
      <div class="ql-editor" v-html="showData.tpContent"/>
      <div class="demo-image__lazy" style="text-align: center">
        <div v-for="url in showData.tpFile" :key="url">
          <el-image :src="url" lazy :preview-src-list="showData.tpFile"/>
          <el-divider></el-divider>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        top="5vh"
        title="记录一下"
        :visible.sync="dialogVisible"
        width="95%">
      <el-row type="flex" justify="space-around">
        <el-col :span="23" style="margin-top: 10px">
          <el-input style="border-top: 0"
                    placeholder="标题"
                    v-model="tpTitle"
                    clearable>
          </el-input>
        </el-col>
      </el-row>
      <quill ref="quill"/>
      <div style="padding: 12px 15px; text-align: center">
        <el-upload
            class="upload-demo"
            action="/api/file/saveToTemp"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="uploadFile"
            list-type="picture"
            ref="fileUpload"
            multiple>
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">上传图片/文件记录~</div>
        </el-upload>
        <el-dialog :visible.sync="dialogVisibleImg" append-to-body>
          <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="emptyData">取 消</el-button>
    <el-button type="primary" @click="insertData">保 存</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import ExpandIndex from "../../../request/expand/index";
import quill from '@/components/quill/quill'
import ask from "@/request/expand/mailbox/index";

export default {
  name: "morningReport",
  components: {quill},
  data() {
    return {
      askPostman: new ask(),
      dialogVisible: false,
      dialogShowVisible: false,
      getRichText: "",
      dialogImageUrl: '',
      dialogVisibleImg: false,
      disabled: false,
      fileList: [],
      postmanList: [],
      showData: {},
      tpTitle: '',
      pageInfo: {
        indexI: 1,
        pages: 0
      }
    }
  },
  computed: {
    ULHeight() {
      return window.innerHeight - 60 - 65 + 'px';
    }
  },
  mounted() {
    this.initData(1)
    let this_ = this;
    document.getElementById("listUl").addEventListener('scroll', function () {
      const ul = document.getElementById('listUl');
      const isBottom = Math.ceil(ul.scrollTop + ul.clientHeight) >= ul.scrollHeight
      if (isBottom && this_.pageInfo.pages > this_.pageInfo.indexI) {
        this_.initData(++this_.pageInfo.indexI);
      }
    })

  },
  methods: {
    initData(pageNum) {
      this.askPostman.timePost({"type": "love", "pageNum": pageNum, "pageSize": "5"}).then(res => {
        res.list.forEach(item => {
          this.postmanList.push(item)
        })
        this.pageInfo.pages = res.pages
      })
    },
    showDataFun(data) {
      this.showData = JSON.parse(JSON.stringify(data))
      if (this.showData.tpFile != null) {
        for (let i = 0; i < this.showData.tpFile.length; i++) {
          this.showData.tpFile[i] = "/api/resources/postman/" + data.tpId + "/" + this.showData.tpFile[i]
        }
      }
      this.dialogShowVisible = true
    },
    resultQuillText(val) {
      this.getRichText = val
    },
    handleRemove(file) {
      this.fileList = this.fileList.filter(item => item !== file.response.msg);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisibleImg = true;
    },
    uploadFile(file) {
      this.fileList.push(file.msg);
    },
    emptyData() {
      this.dialogVisible = false
      this.$refs.quill.emptyContent()
      this.$refs['fileUpload'].clearFiles();
    },
    insertData() {
      let data = {
        tpTitle: this.tpTitle,
        tpTime: new Date(),
        tpToEmail: null,
        tpContent: this.$refs.quill.getContent(),
        tpFile: this.fileList,
        tpType: "love"
      }
      this.askPostman.insTimePostman(data).then(res => {
        if (res.code === 'success') {
          this.emptyData();
          this.tpTitle = ''
          // this.initData()
        }
      })
    },
    del(item) {
      this.$confirm('是否确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'msgBox'
      }).then(() => {
        this.askPostman.delTimePostData(item.tpId).then(res => {
          if (res.code === 'success') {
            const index = this.postmanList.findIndex((profile) => profile.tpId == item.tpId);
            if (index !== -1) {
              this.postmanList.splice(index, 1)
            }
            this.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success'
            });
          }
        })
      }).catch(() => {
      });
    }
  }
}
</script>

<style scoped>
li {
  list-style: none;
}

/deep/ .el-dialog__body {
  padding: 0px;
}

.el-timeline {
  padding: 0px;
}

.ql-editor {
  box-sizing: border-box;
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  -o-tab-size: 4;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}

/deep/ .ql-editor {
  padding: 0px;
}

/deep/ .ql-snow {
  padding-top: 0px;
}

#listUl {
  margin: 0px;
}
</style>